import React from 'react'
import { Box } from '@material-ui/core'
import SEO from "../components/seo"
import Layout from "../components/Layout/"
import AboutUsLayout from '../components/Layout/AboutUsLayout'

const AboutUs = () => {
    return (
        <>
            <SEO title="About" />
            <Layout withFooter withHeader>
                <Box>
                    <AboutUsLayout />
                </Box>
            </Layout>
        </>
    )
}

export default AboutUs
