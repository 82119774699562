import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#F2F8FF",
  },
  body: {
    backgroundColor: "#FFFFFF",
    padding: "5rem 0",
    "& p, li": {
      fontWeight: 400,
      fontSize: `1rem`, //16
    },
  },
  subTitle: {
    color: "#24292E",
    maxWidth: "700px",
    margin: "auto",
    padding: "0px 20px",
    fontSize: "18px",
  },
  link: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    textDecoration: "none",
  },
  paragrapgh: {
    marginTop: "2rem",
  },
  team: {
    marginTop: "4rem",
  },
  gallery: {
    width: "80%",
    margin: "2rem auto",
  },
  aboutDetails: {
    maxWidth: "1200px",
    marginTop: "20px",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  imageContainer1: {
    display: "flex",
    maxWidth: "400px",
    justifyContent: "start",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  imageContainer2: {
    display: "flex",
    justifyContent: "end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  memberDetails: {
    marginTop: "0.5rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  detailsHeader: {
    textAlign: "start",
    color: "#0B1E5B",
    marginTop: "-5px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  detailsSubheader: {
    textAlign: "start",
    fontSize: "1.1rem !important",
    fontWeight: "600 !important",
    lineHeight: "1.5",
    display: "inline",
    marginRight: "5px",
  },
  subheaderContainer: {
    display: "flex",
    margin: "20px 0 10px 0",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  detailsTextContainer: {
    backgroundColor: "#f7f7f7",
    padding: "20px",
    paddingBottom: "40px",
    borderRadius: "10px",
    textAlign: "start",
    "& > p": {
      fontSize: "17px ",
    },
  },
  gridContainer2: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
}))

export { useStyles }
