import React from "react"
import {
  Container,
  Box,
  Typography,
  useMediaQuery,
  Grid,
} from "@material-ui/core"
import { useStyles } from "./style"
import { useTheme } from "@material-ui/styles"
import Data from "../../Layout/Data"

import Ginger from "../../../images/Ginger_CEOO.png"
import Femi from "../../../images/Femi_Shonubi.png"
import Adeyinka from "../../../images/Adeyinka.png"
import Johnson from "../../../images/Johnson__Adekunle.png"
import Tomi from "../../../images/Tomilola__Ogedengbe.png"
import Oluchi from "../../../images/Oluchi__Oladokun.png"
import Ileri from "../../../images/Ileri__PO.png"
import LinkedInIcon from "../../../images/linkedinIcon.png"
import { Link } from "gatsby"

const AboutUsLayout = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const { team } = Data

  return (
    <Box
      id="about"
      position="relative"
      paddingTop={"114px"}
      className={classes.root}
    >
      <Box padding="5rem 1rem ">
        <Typography
          variant="h1"
          align="center"
          gutterBottom
          style={{ color: "#0B1E5B" }}
        >
          Meet the Team
        </Typography>
        <Typography className={classes.subTitle} align="center" gutterBottom>
          Edutech team is staffed with qualified professionals and creative
          minds with a vision to make quality education accessible to everyone.
        </Typography>
      </Box>
      <Box className={classes.body}>
        <Container maxWidth={"lg"}>
          <Box className={classes.aboutDetails} align="center">
            <Grid style={{ marginBottom: "100px" }} container>
              <Grid
                style={
                  isMobile
                    ? { padding: "0px", marginBottom: "10px" }
                    : { padding: "0px 20px 0px 0px" }
                }
                item
                md={5}
                sm={12}
              >
                <div className={classes.imageContainer1}>
                  <img
                    src={Ginger}
                    alt={"Ginger"}
                    width="400px"
                    height="auto"
                    style={{ borderRadius: "5px" }}
                  />
                </div>
              </Grid>
              <Grid
                className={classes.gridItem}
                style={{ padding: "0px 20px 0px 30px" }}
                item
                md={7}
                sm={12}
              >
                <Typography className={classes.detailsHeader} variant="h2">
                  Onyekachi Ginger-Eke
                </Typography>
                <div className={classes.subheaderContainer}>
                  <Typography
                    color="primary"
                    className={classes.detailsSubheader}
                  >
                    CEO and Co-founder
                  </Typography>

                  <Link
                    style={{
                      cursor: "pointer",
                      margin: "0px",
                      marginLeft: "10px",
                    }}
                    to="https://www.linkedin.com/in/onyekachigingereke/"
                    target="_blank"
                  >
                    <img
                      width="25px"
                      height="25px"
                      src={LinkedInIcon}
                      alt="linkedIn icon"
                    />
                  </Link>
                </div>
                <Box className={classes.detailsTextContainer}>
                  <Typography>
                    Onyekachi Ginger-Eke is a dynamic member of our managing
                    team at EduTech Global. As CEO and Co-founder, she drives
                    the company's success with a focus on strategy, business
                    development, and growth. With expertise in general and
                    strategic management, IT solutions, human resources, and
                    more, Ginger excels in executive roles, offering valuable
                    insights and practical solutions in a fast-paced
                    environment.
                    <br /> <br />
                    Her self-driven and result-oriented leadership, backed by
                    strong research, analytical, and team-building skills, adds
                    great value to our team's pursuit of innovation. Pursuing a
                    part-time Doctorate in Business Administration showcases her
                    dedication to thought leadership.
                    <br /> <br />
                    As a Forbes Council Member and judge for prestigious
                    competitions, she brings industry knowledge and discerning
                    insights. Notable accolades, including the Outstanding
                    Leadership and Visionaries Awards, recognize her exceptional
                    contributions to education.
                    <br />A passionate advocate for women's empowerment,
                    Ginger's recognition with the 2023 Black Swan Award and
                    others highlights her commitment to gender equality. As part
                    of our managing team, her visionary thinking and leadership
                    inspire us all as we shape the future of education.
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Grid
              className={classes.gridContainer2}
              style={{ marginBottom: "100px" }}
              container
            >
              <Grid
                className={classes.gridItem}
                style={{ padding: "0px 30px 0px 0px" }}
                item
                md={7}
                sm={12}
              >
                <Typography className={classes.detailsHeader} variant="h2">
                  Femi Shonubi
                </Typography>
                <div className={classes.subheaderContainer}>
                  <Typography
                    color="primary"
                    className={classes.detailsSubheader}
                  >
                    CTO and Co-founder
                  </Typography>

                  <Link
                    style={{
                      cursor: "pointer",
                      margin: "0px",
                      marginLeft: "10px",
                    }}
                    to="https://www.linkedin.com/in/olufemi-shonubi-8abb38153/"
                    target="_blank"
                  >
                    <img
                      width="25px"
                      height="25px"
                      src={LinkedInIcon}
                      alt="linkedIn icon"
                    />
                  </Link>
                </div>
                <Box className={classes.detailsTextContainer}>
                  <Typography>
                    Femi is the CTO/Co-founder of Edutech Global and leads the
                    Technology and Engineering arm of the business. Femi holds a
                    bachelor's degree in physics from the Obafemi Awolowo
                    University. With over ten years’ experience in the I.T.
                    industry, he has designed and implemented custom solutions
                    for projects from various fields - Aviation, Power, Oil and
                    Gas and Education.
                    <br /> <br />
                    Femi started his career as a software analyst where he
                    developed and implemented workflow templates prioritizing
                    efficiency and ease-of-use for end users as well as a
                    project manager, project planning, and conducting scope
                    review for resource planning. He later became the head
                    enterprise architect at Venture Garden Group where he
                    designed internal process improvements to automate
                    repetitive tasks, shortened data delivery times, managed
                    project teams, and ensured applications meet project
                    requirements.
                    <br /> <br />
                    Femi has worked as the Deputy Chief Technology Officer for
                    EduTech Advanced Business Technologies where he made
                    executive decisions on behalf of the business’s technology
                    requirements while maintaining a consumer-focused outlook
                    and aiding in delivery of I.T. projects to market. Femi has
                    led and managed teams of various sizes and is the founder of
                    Multistream Technologies, a company that shapes solutions
                    and approaches by leveraging trends in customer marketplaces
                    and industries.
                  </Typography>
                </Box>
              </Grid>
              <Grid
                style={
                  isMobile
                    ? { padding: "0px 20px 0px 0px", marginBottom: "10px" }
                    : { padding: "0px 20px 0px 0px" }
                }
                item
                md={5}
                sm={12}
              >
                <div className={classes.imageContainer2}>
                  <img
                    src={Femi}
                    alt={"Femi"}
                    width="400"
                    height="auto"
                    style={{ borderRadius: "5px" }}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid style={{ marginBottom: "100px" }} container>
              <Grid
                style={
                  isMobile
                    ? { padding: "0px 20px 0px 0px", marginBottom: "10px" }
                    : { padding: "0px 20px 0px 0px" }
                }
                item
                md={5}
                sm={12}
              >
                <div className={classes.imageContainer1}>
                  <img
                    src={Adeyinka}
                    alt={"Adeyinka"}
                    width="400"
                    height="auto"
                    style={{ borderRadius: "5px" }}
                  />
                </div>
              </Grid>
              <Grid
                className={classes.gridItem}
                style={{ padding: "0px 20px 0px 30px" }}
                item
                md={7}
                sm={12}
              >
                <Typography className={classes.detailsHeader} variant="h2">
                  Adeyinka Adeosun
                </Typography>
                <div className={classes.subheaderContainer}>
                  <Typography
                    color="primary"
                    className={classes.detailsSubheader}
                  >
                    Finance Controller
                  </Typography>
                  <Link
                    style={{
                      cursor: "pointer",
                      margin: "0px",
                      marginLeft: "10px",
                    }}
                    to={"https://www.linkedin.com/in/adeyinka-adeosun-mba-ca-acfe-pmec%E2%84%A2-406387132/"}
                    target="_blank"
                  >
                    <img
                      width="25px"
                      height="25px"
                      src={LinkedInIcon}
                      alt="linkedIn icon"
                    />
                  </Link>
                </div>
                <Box className={classes.detailsTextContainer}>
                  <Typography>
                    Seasoned financial expert and business growth strategist with over 20 years of experience
                    driving organizational success across diverse industries, including oil and gas, academia,
                    real estate, media, and IT. Leveraging expertise garnered through:
                    <ul style={{ listStyleType: 'none', paddingLeft: 0, marginTop: 25, marginBottom: 25 }}>
                      <li>- Specialized training at International Business Management Institute, Berlin</li>
                      <li>- Certification from the esteemed Metropolitan School of Business Management, UK</li>
                      <li>- Bachelor of Science in Accounting, Lautech</li>
                      <li>- Master's degree in Business Management, Lautech</li>
                      <li>- Professional Member, Institute of Chartered Accountants of Nigeria, ICAN</li>
                      <li>- Associate Member, Association of Fraud Examiners, ACFE</li>
                    </ul>
                    Proven track records of developing and implementing effective business growth strategies, fostering innovation, and optimizing financial performance.
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Grid
              className={classes.gridContainer2}
              style={{ marginBottom: "100px" }}
              container
            >
              <Grid
                className={classes.gridItem}
                style={{ padding: "0px 30px 0px 0px" }}
                item
                md={7}
                sm={12}
              >
                <Typography className={classes.detailsHeader} variant="h2">
                  Johnson Adekunle
                </Typography>
                <div className={classes.subheaderContainer}>
                  <Typography
                    color="primary"
                    className={classes.detailsSubheader}
                  >
                    Deputy Chief Technology Officer
                  </Typography>
                  <Link
                    style={{
                      cursor: "pointer",
                      margin: "0px",
                      marginLeft: "10px",
                    }}
                    to={"https://www.linkedin.com/in/johnson-adekunle/"}
                    target="_blank"
                  >
                    <img
                      width="25px"
                      height="25px"
                      src={LinkedInIcon}
                      alt="linkedIn icon"
                    />
                  </Link>
                </div>
                <Box className={classes.detailsTextContainer}>
                  <Typography>
                    Johnson is a seasoned solution architect with over 9 years
                    experience developing business solution in Fintech, Energy,
                    Aviation and Edtech industries. He is a master degree holder
                    from Obafemi Awolowo University, Nigeria. Johnson combines
                    deep knowledge of systems, software, business, product and
                    usability to deliver value to the customer.
                    <br /> <br />
                    Prior to joining Edutech, Johnson has worked in technical
                    leadership capacity at Rensource Energy and Vigipay. He is
                    currently the Deputy Chief Technology Officer (DCTO) at
                    Edutech Global
                  </Typography>
                </Box>
              </Grid>
              <Grid
                style={
                  isMobile
                    ? { padding: "0px 20px 0px 0px", marginBottom: "10px" }
                    : { padding: "0px 20px 0px 0px" }
                }
                item
                md={5}
                sm={12}
              >
                <div className={classes.imageContainer2}>
                  <img
                    src={Johnson}
                    alt={"Johnson"}
                    width="400"
                    height="auto"
                    style={{ borderRadius: "5px" }}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid style={{ marginBottom: "100px" }} container>
              <Grid
                style={
                  isMobile
                    ? { padding: "0px 20px 0px 0px", marginBottom: "10px" }
                    : { padding: "0px 20px 0px 0px" }
                }
                item
                md={5}
                sm={12}
              >
                <div className={classes.imageContainer1}>
                  <img
                    src={Tomi}
                    alt={"Tomi"}
                    width="400"
                    height="auto"
                    style={{ borderRadius: "5px" }}
                  />
                </div>
              </Grid>
              <Grid
                className={classes.gridItem}
                style={{ padding: "0px 20px 0px 30px" }}
                item
                md={7}
                sm={12}
              >
                <Typography className={classes.detailsHeader} variant="h2">
                  Omotomilola Ogedengbe
                </Typography>
                <div className={classes.subheaderContainer}>
                  <Typography
                    color="primary"
                    className={classes.detailsSubheader}
                  >
                    Finance Manager
                  </Typography>
                  <Link
                    style={{
                      cursor: "pointer",
                      margin: "0px",
                      marginLeft: "10px",
                    }}
                    to={
                      "https://www.linkedin.com/in/omotomilola-ogedengbe-5b879411"
                    }
                    target="_blank"
                  >
                    <img
                      width="25px"
                      height="25px"
                      src={LinkedInIcon}
                      alt="linkedIn icon"
                    />
                  </Link>
                </div>
                <Box className={classes.detailsTextContainer}>
                  <Typography>
                    Omotomilola is a highly skilled Financial Data Analyst with
                    experience spanning 15years in designing and implementing
                    business solutions to assist with the development of
                    financial statements following the mission and core values
                    of organizations. She is proficient in Financial
                    Forecasting, Financial Accounting Reporting, Tax Planning
                    and Data Analytics.
                    <br /> <br />
                    Before joining the Venture Garden Group as a Finance Manager
                    in 2018, Omotomilola was Head, Accounts & Finance at Digital
                    Jewels Ltd, a leading indigenous consulting firm in Africa
                    where she worked for close to a decade. Prior to her role at
                    Digital Jewels, Omotomilola had worked in diverse financial
                    positions with multiple companies varying from banking,
                    insurance; to retail and manufacturing industries. She is
                    currently the Financial Controller at Edutech
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Grid
              className={classes.gridContainer2}
              style={{ marginBottom: "100px" }}
              container
            >
              <Grid
                className={classes.gridItem}
                style={{ padding: "0px 30px 0px 0px" }}
                item
                md={7}
                sm={12}
              >
                <Typography className={classes.detailsHeader} variant="h2">
                  Oluchi Oladokun
                </Typography>
                <div className={classes.subheaderContainer}>
                  <Typography
                    color="primary"
                    className={classes.detailsSubheader}
                  >
                    Head, Branding, Marketing and Communications.
                  </Typography>
                  <Link
                    style={{
                      cursor: "pointer",
                      margin: "0px",
                      marginLeft: "10px",
                    }}
                    to={
                      "https://www.linkedin.com/in/oluchi-oladokun-arpa-a22a7752/"
                    }
                    target="_blank"
                  >
                    <img
                      width="25px"
                      height="25px"
                      src={LinkedInIcon}
                      alt="linkedIn icon"
                    />
                  </Link>
                </div>
                <Box className={classes.detailsTextContainer}>
                  <Typography>
                    Oluchi is a marketing communication professional with over a
                    decade long experience in delivering result-oriented and
                    innovative marketing campaigns; providing advisory that
                    facilitates market entry, drives growth, and increases
                    organizational and product visibility across diverse sectors
                    - Edtech, Financial institutions, OEMs, Multinationals, and
                    FMCGs within Nigeria and across SSA.
                    <br />
                    <br />
                    A registered advertising practitioner with a professional
                    diploma in advertising from the Advertising Regulatory
                    Council of Nigeria, Oluchi began her career in client
                    service management and subsequently joined the marketing
                    communications industry, through the Insight Redefini Group,
                    where she led teams, at All Seasons Zenith - a
                    communications planning and media buying ROI agency, to
                    launch successful target driven campaigns for brands across
                    several industries including TikTok, Power Horse, ALAT by
                    Wema, Chi Brands (Chivita Juices, Hollandia drinks) amongst
                    others. Thereafter, she joined forces with Casia Growth Lab
                    (now Log10 media) as the lead marketing consultant utilizing
                    advisory and campaign execution knowledge for clients in the
                    edtech and mobility tech industries respectively.
                    <br />
                    <br />
                    Her expertise devising effective brand communication
                    strategies and a thorough understanding of market and media
                    dynamics have enabled her to garner a track record of
                    driving brand equity, share, and accelerated growth. Oluchi
                    is currently leading the branding, marketing, and
                    communications team at EduTech Global.
                  </Typography>
                </Box>
              </Grid>
              <Grid
                style={
                  isMobile
                    ? { padding: "0px 20px 0px 0px", marginBottom: "10px" }
                    : { padding: "0px 20px 0px 0px" }
                }
                item
                md={5}
                sm={12}
              >
                <div className={classes.imageContainer2}>
                  <img
                    src={Oluchi}
                    alt={"Oluchi"}
                    width="400"
                    height="auto"
                    style={{ borderRadius: "5px" }}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid style={{ marginBottom: "100px" }} container>
              <Grid
                style={
                  isMobile
                    ? { padding: "0px 20px 0px 0px", marginBottom: "10px" }
                    : { padding: "0px 20px 0px 0px" }
                }
                item
                md={5}
                sm={12}
              >
                <div className={classes.imageContainer1}>
                  <img
                    src={Ileri}
                    alt={"Ilerioluwa"}
                    width="400"
                    height="auto"
                    style={{ borderRadius: "5px" }}
                  />
                </div>
              </Grid>
              <Grid
                className={classes.gridItem}
                style={{ padding: "0px 20px 0px 30px" }}
                item
                md={7}
                sm={12}
              >
                <Typography className={classes.detailsHeader} variant="h2">
                  Ilerioluwa Ogunnariwo
                </Typography>
                <div className={classes.subheaderContainer}>
                  <Typography
                    color="primary"
                    className={classes.detailsSubheader}
                  >
                    Lead, People Operations
                  </Typography>
                  <Link
                    style={{
                      cursor: "pointer",
                      margin: "0px",
                      marginLeft: "10px",
                    }}
                    to={
                      "https://www.linkedin.com/in/ilerioluwa-tobiloba-acipm-sphri-07926b96"
                    }
                    target="_blank"
                  >
                    <img
                      width="25px"
                      height="25px"
                      src={LinkedInIcon}
                      alt="linkedIn icon"
                    />
                  </Link>
                </div>
                <Box className={classes.detailsTextContainer}>
                  <Typography>
                    Ilerioluwa Ogunnariwo is a transformational Human Resource
                    Leader with over 17 years of experience that cut across
                    Telecommunications, Hospitality, Consulting, Publishing,
                    Construction, and Sales. Ilerioluwa is a graduate of Lagos
                    State University, where he obtained a B.Sc. in Industry
                    Relations and Personnel Management and a Master's in
                    International Human Resource Management from Rome Business
                    School, he is a professional member of the Chartered
                    Institute of Personnel Management Nigeria (CIPM) and a
                    Senior Professional Human Resource International (SPHRi)
                    from Human Resource Certification Institute, U.S. (HRCI). He
                    is married and blessed with children.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default AboutUsLayout
